import { Theme, createStyles, makeStyles } from "@material-ui/core/styles"

export default makeStyles((theme: Theme) =>
  createStyles({
    main: {
      margin: "4em 0",
      display: "flex",
      alignItems: "center",
      transition: "all .5s ease",
      [theme.breakpoints.down(800)]: {
        flexDirection: "column-reverse"
      }
    },
    section: {
      transition: "all .3s ease",
      width: "50%",
      [theme.breakpoints.down(800)]: {
        width: "100%"
      }
    },
    typography: {
      transition: "all .3s ease",
      marginBottom: ".8em",
      [theme.breakpoints.down(400)]: {
        textAlign: "center",
        fontSize: "1.5rem"
      }
    },
    form: {
      transition: "all .3s ease",
      paddingRight: 35,
      maxWidth: 500,
      width: "100%",
      [theme.breakpoints.down(800)]: {
        paddingRight: 0,
        margin: "0 auto"
      }
    },
    btn: {
      color: theme.palette.neutral.main,
      cursor: "pointer"
    },
    image: {
      transition: "all .3s ease",
      minWidth: 100,
      width: "100%",
      margin: "0 auto",
      [theme.breakpoints.down(800)]: {
        marginBottom: "3em",
        maxWidth: 500
      },
      [theme.breakpoints.down(600)]: {
        maxWidth: 250
      },
      [theme.breakpoints.down(450)]: {
        maxWidth: 200
      }
    },
    wrapper: {
      margin: theme.spacing(1),
      position: "relative"
    },
    fabProgress: {
      color: theme.palette.primary.main,
      position: "absolute",
      top: -6,
      left: -6,
      zIndex: 1
    }
  })
)
