import React, { ChangeEvent, MouseEvent, useState } from "react"
import axios from "axios"
import Textfield from "@material-ui/core/TextField"
import Typography from "@material-ui/core/Typography"
import CircularProgress from "@material-ui/core/CircularProgress"
import Snackbar from "@material-ui/core/Snackbar"
import Alert from "@material-ui/lab/Alert"
import Fab from "@material-ui/core/Fab"
import useStyle from "../styles/contact"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Fade from "../components/Fade"
import Image from "../components/image"

const InitialVal: InputState = { name: "", msg: "", email: "" }

function validate(obj: InputState) {
  let err = []
  if (obj.name.trim() === "") {
    err.push("name")
  }
  if (obj.msg.trim() === "") {
    err.push("message")
  }
  if (obj.email.trim() === "") {
    err.push("email")
  }
  if (err.length > 0) {
    throw new Error(`Please fill in: ${err.join(", ")}`)
  } else {
    return
  }
}

const Contact: React.FC<Props> = props => {
  const c = useStyle()
  const [submitting, setSubmitting] = useState(false)
  const [open, setOpen] = useState(false)
  const [val, setVal] = useState<InputState>(InitialVal)

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    setVal({ ...val, [e.target.name]: e.target.value })
  }

  const handleServerResponse = (success: Boolean) => {
    if (success) {
      setOpen(true)
      setSubmitting(false)
      setVal(InitialVal)
    } else {
      alert("failed to send")
    }
  }

  const handleOnSubmit = async (e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault()
    setSubmitting(true)
    try {
      validate(val)
      await axios.post("/.netlify/functions/contactme", JSON.stringify(val))
      handleServerResponse(true)
    } catch (e) {
      setSubmitting(false)
      alert(e.message)
    }
  }

  const handleClose = (event?: React.SyntheticEvent, reason?: string) => {
    if (reason === "clickaway") return
    setOpen(false)
  }

  return (
    <Layout uri={props.uri}>
      <SEO title="Contact" />
      <Fade>
        <Snackbar open={open} autoHideDuration={4000} onClose={handleClose}>
          <Alert
            elevation={6}
            variant="filled"
            onClose={handleClose}
            severity="success"
          >
            Message sent!
          </Alert>
        </Snackbar>
        <main className={c.main}>
          <section className={c.section}>
            <div>
              <form className={c.form}>
                <Typography
                  className={c.typography}
                  variant="h4"
                  color="secondary"
                >
                  Send me a message
                </Typography>
                <Textfield
                  fullWidth
                  variant="outlined"
                  margin="normal"
                  onChange={handleChange}
                  value={val.email}
                  type="email"
                  name="email"
                  label="Email"
                  required={true}
                />
                <Textfield
                  fullWidth
                  variant="outlined"
                  margin="normal"
                  onChange={handleChange}
                  value={val.name}
                  name="name"
                  label="Name"
                  required={true}
                />
                <Textfield
                  fullWidth
                  variant="outlined"
                  margin="normal"
                  onChange={handleChange}
                  value={val.msg}
                  name="msg"
                  label="Message"
                  required={true}
                />
                <br />
                <br />
                <div className={c.wrapper}>
                  <Fab
                    aria-label="save"
                    color="primary"
                    onClick={handleOnSubmit}
                    disabled={submitting}
                    className={c.btn}
                  >
                    Send
                  </Fab>
                  {submitting && (
                    <CircularProgress size={68} className={c.fabProgress} />
                  )}
                </div>
              </form>
            </div>
          </section>
          <section className={c.section}>
            <div className={c.image}>
              <Image name="message.png" />
            </div>
          </section>
        </main>
      </Fade>
    </Layout>
  )
}

export default Contact

interface Props {
  uri: string
}

interface InputState {
  name: String
  email: String
  msg: String
}

declare global {
  namespace NodeJS {
    export interface ProcessEnv {
      NODE_ENV: "development" | "production" | "test"
      GATSBY_EMAIL_URL: string
    }
  }
}
